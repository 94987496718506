<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

// TODO superseded by AppMenuExpandable - delete at some point

const client = useClient()
const supabase = useSupabaseClient()

const auth = useAuth()
const route = useRoute()

function isCurrent(path) {
  return `${route.path}/` === path || route.path.startsWith(path)
}

const navigation = reactive([
  {
    name: 'Home',
    href: '/dashboard/',
    icon: 'home.svg',
  },
  {
    name: 'Token Designer',
    href: '/token-designer/',
    icon: 'token-designer.svg',
  },

  {
    name: 'Tools & Services',
    icon: 'tools.svg',
    children: [
      { name: 'Tokenomics', href: '/tokenomics/' },
      { name: 'Growth Capital', href: '/growth-capital/' },
      { name: 'AutoDistribution', href: '/auto-distribution/' },
      { name: 'Business Intelligence', href: '/business-intelligence' },
      { name: 'Listing & Liquidity', href: '/listing-and-liquidity/' },
      { name: `AMM<sup>2</sup>`, href: '/automated-market-making/' },
      {
        name: 'Engage a Market Maker',
        href: '/engage-a-market-maker/',
      },
      {
        name: 'Market Maker Monitoring',
        href: '/market-maker-monitoring/start',
      },
    ],
  },
  {
    name: 'PathFinder',
    href: '/pathfinder/',
    icon: 'pathfinder.svg',
  },
  {
    name: 'Education',
    icon: 'education.svg',
    children: [
      {
        name: 'Forgd GitBook',
        href: 'https://forgd.gitbook.io/explore/',
        icon: 'i-heroicons-arrow-top-right-on-square',
        external: true,
      },
      {
        name: 'FAQs',
        href: 'https://forgd.gitbook.io/explore/faqs',
        icon: 'i-heroicons-arrow-top-right-on-square',
        external: true,
      },
    ],
  },
  {
    name: 'About',
    icon: 'about.svg',
    children: [
      { name: 'What is Forgd?', href: '/what-is-forgd/' },
      { name: 'Fees and pricing', href: '/fees-and-pricing/' },
      { name: 'Coming Soon', href: '/coming-soon/' },
    ],
  },
])

async function logout() {
  await supabase.auth.signOut()
  auth.logout()
}
</script>

<template>
  <div
    class="w-[260px] flex-shrink-0 border-r px-2 bg-forgd-primary-900 font-display flex flex-col justify-between max-h-screen pb-2 sticky top-0"
  >
    <NuxtLink to="/dashboard/" class="block py-8">
      <img src="/lp/logo-light.svg" class="w-[151px] h-[33px] mx-auto">
    </NuxtLink>

    <div class="overflow-y-scroll h-full no-scrollbar">
      <nav>
        <ul role="list" class="flex flex-1 flex-col justify-start gap-y-7">
          <li>
            <ul role="list" class="space-y-2 font-display">
              <li v-for="item in navigation" :key="item.name">
                <NuxtLink
                  v-if="!item.children"
                  :to="item.href"
                  class="group flex items-center gap-x-2 rounded-lg p-2 text-sm leading-6 font-semibold text-white"
                  :class="[
                    isCurrent(item.href)
                      ? 'bg-forgd-primary-600'
                      : 'hover:bg-forgd-primary-600',
                  ]"
                >
                  <img
                    :src="`/icons/${item.icon}`"
                    class="h-5 w-5 shrink-0 text-gray-400"
                  >
                  <span v-html="item.name" />
                </NuxtLink>
                <Disclosure v-else v-slot="{ open }" as="div">
                  <DisclosureButton
                    class="flex items-center w-full text-left rounded-md p-2 gap-x-2 text-sm leading-6 font-semibold text-white"
                    :class="[
                      item.current
                        ? 'bg-forgd-primary-600'
                        : 'hover:bg-forgd-primary-600',
                    ]"
                  >
                    <img
                      :src="`/icons/${item.icon}`"
                      class="h-5 w-5 shrink-0 text-white"
                    >
                    <span v-html="item.name" />
                    <ChevronDownIcon
                      class="ml-auto h-5 w-5 shrink-0 text-white"
                      :class="[open ? 'rotate-180' : '']"
                      aria-hidden="true"
                    />
                  </DisclosureButton>
                  <DisclosurePanel as="ul" class="mt-3 px-2">
                    <li v-for="subItem in item.children" :key="subItem.name">
                      <NuxtLink
                        :to="subItem.href"
                        class="flex items-center space-x-2 rounded-lg py-[7px] pr-2 pl-3 text-sm leading-6 font-normal text-gray-400 hover:text-forgd-bgd-100"
                        :class="[
                          isCurrent(subItem.href)
                            ? 'bg-forgd-gray-700'
                            : 'hover:bg-forgd-gray-700',
                        ]"
                        :target="subItem.external ? '_blank' : '_self'"
                      >
                        <span v-html="subItem.name" />
                        <UIcon v-if="subItem.icon" :name="subItem.icon" />
                      </NuxtLink>
                    </li>
                  </DisclosurePanel>
                </Disclosure>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>

    <div class="mt-4">
      <NuxtLink
        to="/support/"
        class="block p-4 rounded-lg bg-forgd-primary-700 text-white inset-x-2 space-y-2"
      >
        <div class="flex justify-between">
          <span class="text-sm font-semibold">Need Support</span>
          <svg
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
          >
            <path
              d="M11.6667 4.16666L17.5 10M17.5 10L11.6667 15.8333M17.5 10H2.5"
              stroke="#F2F2F1"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="text-xs font-normal">
          Contact the Forgd team for a 1:1 to get acclimated with the platform.
        </div>
      </NuxtLink>

      <NuxtLink
        to="/settings"
        class="hover:bg-forgd-primary-600 group flex items-center gap-x-2 rounded-lg p-2 text-sm leading-6 font-semibold text-white mt-2"
      >
        <img src="/icons/settings.svg" class="h-5 w-5 shrink-0 text-gray-400">
        Settings
      </NuxtLink>

      <a
        href="#"
        class="hover:bg-forgd-primary-600 group flex items-center gap-x-2 rounded-lg p-2 text-sm leading-6 font-semibold text-white mt-2"
        @click.prevent="logout"
      >
        <img src="/icons/logout.svg" class="h-5 w-5 shrink-0 text-gray-400">
        Logout
      </a>
    </div>
  </div>
</template>
